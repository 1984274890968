import VisibleMixin from '../visible_properties_mixin'
import RequiredMixin from '../required_properties_mixin'
export default {
  mixins: [VisibleMixin, RequiredMixin],
  inject: {
    isEditor: {
      default: () => false
    }
  },
  props: {
    value: {
      frozen: true
    },
    guid: {
      type: String,
      frozen: true
    },
    size: {
      type: String,
      description: 'Размер',
      editor: 'Size'
    },
    align: {
      type: String,
      description: 'Выравнивание'
    },
    margin: {
      type: String,
      description: 'Отступы',
      default: '5px 10px'
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    block: {
      type: Boolean,
      description: 'Во всю строку',
      default: true
    },
    wrapper: {
      type: Boolean,
      description: 'Блок',
      default: false
    },
    isShow: {
      description: 'Скрыть',
      type: Boolean,
      frozen: true
    },
    pluginName: {
      type: String,
      editor: 'Plugin',
      frozen: true
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно',
      default: false
    },
    name: {
      type: String,
      editor: 'TreeForAttr',
      description: 'Атрибут',
      options: {
        removeSpaces: true
      }
    },
    isHiddenFromBlock: {
      type: Boolean,
      default: false,
      frozen: true
    }
  },
  mounted () {
    if (this.isShow) {
      this.$set(this.hiddenCondition, 'type', 'always')
    }
    if (this.required) {
      // Переключить режим блокирования на "всегда"
      this.$set(this.isRequired, 'type', 'always')
      // Убрать галочку с поля @TODO Убрать после замены required на isRequired
      this.$emit('change-property', { name: 'required', value: false })
    }
    if (this.pluginName && this.editorChart.type !== 'extend_object' && !this.editorChart.plugin) {
      this.$set(this.editorChart, 'plugin', this.pluginName)
    }
  }
}
