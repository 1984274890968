import ConditionsMixin from './conditions_mixin'
export default {
  mixins: [ConditionsMixin],
  props: {
    hiddenCondition: {
      type: Object,
      editor: 'Conditions',
      options: { label: 'Скрывать' },
      default: () => {
        return {
          type: 'never',
          condition_type: 'and',
          conditions: []
        }
      }
    }
  },
  computed: {
    isHidden () {
      return this.checkConditions(this.hiddenCondition)
    }
  },
  watch: {
    isHidden: {
      handler (value) {
        this.$emit('toggle-hide', value)
      },
      immediate: true
    }
  }
}
