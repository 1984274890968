import PropertiesMixin from './PropertiesMixin'

export default {
  // inject: ['getDashboardComponents', 'getModel'],
  mixins: [PropertiesMixin],
  inject: {
    getDashboardComponents: {
      default: () => {
      }
    },
    getCard: {
      default: () => {
      }
    }
  },
  props: {
    CSS: {
      type: String,
      description: 'CSS стили'
    },
    CSSClasses: {
      type: String,
      description: 'CSS классы'
    },
    readonly: {
      description: 'Заблокировать',
      type: Boolean
    }
  }
}
